import { EventEmitter, ModelSignal, Signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { UtilService } from '@app/_services/util.service';
import {
  DistinctUntilChangedBy,
  DistinctUntilCosmosChange,
  DistinctUntilDiff,
} from '@app/_validators/custom-validators';
import { TRange } from '@app/shared/time-range-picker/time-range-constants';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import {
  addDays,
  addISOWeekYears,
  addMonths,
  addSeconds,
  addWeeks,
  clamp as clampDate,
  differenceInDays,
  differenceInWeeks,
  endOfDay,
  endOfISOWeek,
  endOfMonth,
  endOfYear,
  isBefore,
  isFuture,
  isSameDay,
  isSameISOWeek,
  isSameMonth,
  isSameYear,
  isValid,
  parse as parseFromString,
  startOfDay,
  startOfToday,
  startOfWeek,
} from 'date-fns/esm';
import { clamp, clone, get, isDate, merge } from 'lodash-es';
import { BehaviorSubject, Observable, OperatorFunction, Subject, defer, pipe } from 'rxjs';
import { distinctUntilChanged, first, map, pairwise, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { firstBy } from 'thenby';
import {
  GroupDefaults,
  Logger,
  Project,
  ProjectPermissionRole,
  Task,
  Time,
  UserSettings,
  Workspace,
  WorkspaceUserSchedule,
} from 'timeghost-api';
export class NotImplementedException extends Error {
  constructor() {
    super('Not implemented');
  }
}
export type DateTime = string | number | Date;
export type DateRangeType = 'day' | 'week' | 'month';
export function isNullOrUndefined(obj: any) {
  return obj === undefined || obj === null;
}
export function filterNaN(obj: any) {
  if (typeof obj !== 'number') return false;
  return isNullOrUndefined(obj) || Number.isNaN(obj);
}
/**
 *
 * @param from where to start from
 * @param to where to end
 * @param dynamicRange allow dynamically setting the range type (weekly, daily or monthly)
 * @returns [Array<[Date, T]>, DateRangeType] - [range in a array, type of range (month, week, day)]
 */
export function createDateRangeArray<T = any>(
  from: Date,
  to: Date,
  dynamicRange: boolean = false,
): [Array<[Date, T]>, DateRangeType] {
  let _data: Array<[Date, T]> = [];
  let _type: DateRangeType = 'day';
  if (dynamicRange && differenceInDays(to, from) >= 28) {
    if (differenceInWeeks(to, from) > 4) {
      _type = 'month';
      for (let d = startOfDay(from); isBefore(d, to); d = addMonths(d, 1)) {
        _data.push([new Date(d.getTime()), null]);
      }
    } else {
      _type = 'week';
      for (let d = startOfDay(from); isBefore(d, to); d = addISOWeekYears(d, 1)) {
        _data.push([new Date(d.getTime()), null]);
      }
    }
  } else {
    for (let d = startOfDay(from); isBefore(d, to); d = addDays(d, 1)) {
      _data.push([new Date(d.getTime()), null]);
    }
  }
  return [_data, _type];
}
/**
 *
 * @param from where to start from
 * @param to where to end
 * @param dynamicRange allow dynamically setting the range type (weekly, daily or monthly)
 * @returns [Array<[Date, Date, T]>, DateRangeType] - [range in a array, type of range (month, week, day)]
 */
export function createDateArrayWithRange<T = any>(
  from: Date,
  to: Date,
  dynamicRange: boolean = false,
): [Array<[Date, Date, T]>, DateRangeType] {
  let _data: Array<[Date, Date, T]> = [];
  let _type: DateRangeType = 'day';
  if (dynamicRange && differenceInDays(to, from) >= 28) {
    if (differenceInWeeks(to, from) > 4) {
      _type = 'month';
      for (let d = startOfDay(from); isBefore(d, to); d = addMonths(d, 1)) {
        _data.push([new Date(d.getTime()), clampDate(addMonths(d, 1), { start: d, end: to }), null]);
      }
    } else {
      _type = 'week';
      for (let d = startOfDay(from); isBefore(d, to); d = addWeeks(d, 1)) {
        _data.push([new Date(d.getTime()), clampDate(addWeeks(d, 1), { start: d, end: to }), null]);
      }
    }
  } else {
    for (let d = startOfDay(from); isBefore(d, to); d = addDays(d, 1)) {
      _data.push([new Date(d.getTime()), clampDate(addDays(d, 1), { start: d, end: to }), null]);
    }
  }
  return [_data, _type];
}
export function createDateRange(
  from: Date,
  to: Date,
  options: Partial<{ inclusiveEnd: boolean; inclusiveStart: boolean }> = {},
) {
  let _data: Date[] = options.inclusiveStart ? [new Date(startOfDay(from))] : [];
  for (let d = startOfDay(from); isBefore(d, to); d = addDays(d, 1)) {
    _data.push(new Date(d.getTime()));
  }
  if (options.inclusiveEnd) _data.push(startOfDay(to));
  return _data;
}
export function splitLongRangeToDay(start: Date, end: Date) {
  const [range] = createDateRangeArray(start, end, false);
  range[0][0].setHours(start.getHours(), start.getMinutes(), start.getSeconds(), 0);
  range[range.length - 1][0].setHours(end.getHours(), end.getMinutes(), end.getSeconds(), 0);
  return range.reduce((acc, [r]) => {
    acc.push(r);
    return acc;
  }, [] as Date[]);
}
export function seriesTypeDateCheck(
  seriesType: 'day' | 'week' | 'month' | 'year',
  dateLeft: string | number | Date,
  dateRight: string | number | Date,
) {
  return !!(
    seriesType === 'day'
      ? isSameDay
      : seriesType === 'week'
        ? isSameISOWeek
        : seriesType === 'month'
          ? isSameMonth
          : seriesType === 'year'
            ? isSameYear
            : null
  )?.(new Date(dateLeft), new Date(dateRight));
}
export function seriesTypeDateEnd(seriesType: 'day' | 'week' | 'month' | 'year', dateLeft: string | number | Date) {
  return (
    seriesType === 'day'
      ? endOfDay
      : seriesType === 'week'
        ? endOfISOWeek
        : seriesType === 'month'
          ? endOfMonth
          : seriesType === 'year'
            ? endOfYear
            : null
  )?.(new Date(dateLeft));
}
export const DEFAULT_PERMISSION_SELF_ID = '33333333-3333-3333-3333-333333333333';
export const DEFAULT_PERMISSION_GROUPS = {
  Everyone: GroupDefaults.everyoneGroupId,
  Admin: GroupDefaults.adminGroupId,
  Self: DEFAULT_PERMISSION_SELF_ID,
};
export const hasPermission = (permissionId: string, userSettings: UserSettings, project?: { id: string }): boolean => {
  if (DEFAULT_PERMISSION_GROUPS.Everyone === permissionId) return true;
  const isAdmin = !!userSettings.workspace.users.find((x) => x.admin && x.id === userSettings.id);
  if (isAdmin) return true;
  const user = userSettings.workspace.groups
    ?.find?.((x) => x.id === permissionId)
    ?.users?.find?.((x) => userSettings.id === x.id);
  if (user) return true;
  if (project) {
    const projectPerm = userSettings.workspace.projectPermissions.find((x) => x.projectId === project.id);
    return (
      !projectPerm.private ||
      !!projectPerm.users?.find((x) => x && x.id === userSettings.id && !x.removed) ||
      !!projectPerm.groups?.find(
        (x) =>
          x &&
          !x.removed &&
          userSettings.workspace.groups.find((g) => g.id === x.id && g.users.find((u) => u.id === userSettings.id)),
      )
    );
  }
  return false;
};
export function hasPerm(permKey: keyof Workspace['permissionSettings'], user: UserSettings) {
  throw new NotImplementedException();
  const perm = user.workspace.permissionSettings[permKey];
  if (!perm) return false;
  return false;
}
export const hasUserInProject = (user: UserSettings, project: Project, opts?: Partial<{ admin: boolean }>) => {
  const options = opts || {};
  if (!user) return false;
  if (options.admin !== false && user.workspace.users.find((x) => x.id === user.id && x.admin)) return true;
  if (
    project.users?.find((u) => u.id === user.id && !u.removed) ||
    project.groups?.find(
      (g) =>
        !g.removed &&
        user.workspace?.groups?.find((wg) => g.id === wg.id && wg.users?.find((wgu) => wgu.id === user.id)),
    )
  )
    return true;

  return false;
};
export const hasPermissionTaskView = (
  task: Task,
  project: Project,
  user: UserSettings,
  _options?: Partial<{ public: boolean }>,
) => {
  const options = _options ?? { public: false };
  if (!project) return false;
  if (!options.public && !project?.private) return true;
  if (!!user.workspace.users.find((x) => x.id === user.id && x.admin)) return true;
  if (
    project.users?.find((x) => !x.removed && x.id === user.id && x.role === ProjectPermissionRole.manager) ||
    project.groups?.find(
      (x) =>
        !x.removed &&
        x.role === ProjectPermissionRole.manager &&
        user.workspace.groups?.find((g) => g.users.find((gu) => gu.id === user.id)),
    )
  )
    return true;
  if (!task.assignedToGroups?.length && !task.assignedToUsers?.length) return true;
  if (task.assignedToUsers.findIndex((x) => x.id === user.id) !== -1) return true;
  const groups = task.assignedToGroups!.map((x) => user.workspace.groups?.find((g) => g.id === x.id)).filter(Boolean);
  if (groups?.find((x) => x.users.findIndex((u) => u.id === user.id) !== -1)) return true;
  return false;
};
export const hasPermissionTaskChange = (task: Task, project: Project, user: UserSettings) => {
  if (
    !!user.workspace.users?.find((x) => x.id === user.id && x.admin) ||
    (project &&
      (!!project.users?.find((x) => !x.removed && x.id === user.id && x.role === ProjectPermissionRole.manager) ||
        !!project.groups?.find(
          (x) =>
            !x.removed &&
            x.role === ProjectPermissionRole.manager &&
            user.workspace.groups?.find((g) => g.users.find((u) => u.id === user.id)),
        )))
  )
    return true;
  return false;
};
export interface RXValue<T> {
  value: T;
  value$: Observable<T>;
  asObservable: (share?: boolean, restartOnInit?: boolean) => Observable<T>;
  asEventEmitter: () => EventEmitter<T>;
  update: (fn: T | ((state: T) => void) | ((state: T) => T), clone?: boolean) => void;
  merge: (state: Partial<T>) => void;
  setProperty: <K extends keyof T = keyof T>(key: K, value: T[K]) => void;
  next: (value: T) => void;
  refresh(): void;
  (): T;
}
export interface RxOptions<T>
  extends Partial<{
    log: Logger | string;
    startWithValue: T;
    addOperators: OperatorFunction<T, T>;
    hooks: {
      change: (value: T, prevValue: T) => void;
    };
    distinct: boolean;
  }> {}
/**
 *
 * @param defaultValue default value
 * @param options options, defaults: distinct=true
 * @returns rx class instance
 */
export function createRxValue<T, R extends RXValue<T> = RXValue<T>>(defaultValue?: T, options?: RxOptions<T>): R {
  const { addOperators, startWithValue } = options ?? {};
  const log = options?.log
    ? typeof options?.log === 'string'
      ? new Logger(`[${options.log}@RX]`)
      : options.log instanceof Logger
        ? options.log
        : undefined
    : undefined;
  const hookChange = options?.hooks?.change;
  return new (class {
    private _valueEmitter: EventEmitter<T>;
    private _value = new BehaviorSubject<T>(defaultValue);
    private _closed = false;
    get closed() {
      return this._closed;
    }
    set closed(closedSource: boolean) {
      this._closed = closedSource;
    }
    public readonly value$: Observable<T> = this._value
      .asObservable()
      .pipe((source) =>
        (startWithValue ? source.pipe(startWith(startWithValue)) : source).pipe((dsrc) =>
          options?.distinct !== false ? dsrc.pipe(distinctUntilChangedJson()) : dsrc,
        ),
      )
      .pipe(
        tap((val) => {
          this._valueEmitter?.emit?.(val);
          log?.debug('valueUpdate', val);
        }),
      )
      .pipe((source) => addOperators?.(source) ?? source);
    public get value() {
      return this._value.getValue();
    }
    public call(): T {
      return this.value;
    }
    public update(fn: T | ((state: T) => T), isCloned?: boolean) {
      const isFunc = typeof fn === 'function';
      const newValue = isFunc ? (fn as Function)(!isCloned ? this.value : clone(this.value)) : fn;
      if (isFunc && newValue === undefined) {
        this.next(this.value);
        return;
      }
      this.next(newValue);
    }
    public merge(valueToMerge: Partial<T>) {
      this.update((s) => {
        return merge(s, valueToMerge);
      }, true);
    }
    public setProperty<K extends keyof T>(key: K, value: T[K]) {
      this.update((s) => {
        s[key] = value;
        return s;
      }, true);
    }
    public refresh() {
      this.next(this._value.getValue());
    }
    public next(value: T) {
      if (hookChange) hookChange(value, typeof this.value === 'object' ? clone(this.value) : this.value);
      this._value.next(value);
    }
    public set value(val: T) {
      this.next(val);
    }
    public asObservable(share?: boolean, restartOnInit?: boolean) {
      return this.value$.pipe((s) => {
        if (share) s = s.pipe(shareReplay());
        if (restartOnInit) s = s.pipe(startWith(this.value));
        return s;
      });
    }
    public asEventEmitter() {
      if (!this._valueEmitter) this._valueEmitter = new EventEmitter<T>(true);
      return this._valueEmitter;
    }
  })() as any as R;
}

export interface RXCollection<T> {
  value$: Observable<T[]>;
  asObservable: (share?: boolean) => Observable<T[]>;
  add: (v: T[], options?: StoreOptions) => this;
  remove: (v: T[], options?: StoreOptions) => this;
  contains: (v: T) => boolean;
  set: (v: T[], options?: StoreOptions) => this;
  value: T[];
  __type: T[];
}
type StoreOptions = {
  emit?: boolean;
};
export function createRxCollection<T>(uniqueOrMapByKey?: (t: T) => any, defaultValue?: T[]): RXCollection<T> {
  let uniqueFn = uniqueOrMapByKey;
  let _store = createRxValue<T[]>(defaultValue ?? []);
  return new (class {
    readonly __type: T[];
    public readonly value$: Observable<T[]> = _store.asObservable().pipe(startWith(this.store), distinctUntilChanged());
    public asObservable(share?: boolean) {
      if (share) return this.value$.pipe(shareReplay());
      return this.value$;
    }
    private get store() {
      return _store.value;
    }
    private set store(val: T[]) {
      _store.value = val;
    }
    public add(v: T[], options?: StoreOptions) {
      if (uniqueFn) {
        const ids = v.map((x) => uniqueFn(x));
        if (!this.store.find((x) => ids.includes(uniqueFn(x)))) this.store = [...this.store, ...v];
      } else this.store = [...this.store, ...v];
      return this;
    }
    public contains(v: T) {
      if (uniqueFn) return this.store.findIndex((x) => uniqueFn(v) === uniqueFn(v)) !== -1;
      return this.store.findIndex((x) => x === v) !== -1;
    }
    public set(v: T[], options?: StoreOptions) {
      this.store = v;
      return this;
    }
    public remove(v: T[], options?: StoreOptions) {
      const toRemove = v.map((x) => uniqueFn(x));
      this.store = this.store.filter((x) => !toRemove.includes(uniqueFn(x)));
      return this;
    }
    get value() {
      return [...this.store];
    }
  })();
}
export function fromRxValue<T>(
  source: Observable<T> | (() => Observable<T>),
  defaultValue?: T,
  startWithValue?: T,
  options?: { resubscribeOn?: Subject<void> },
) {
  const rx: RXValue<T> & { closed: boolean } = createRxValue<T>(defaultValue, {
    ...(startWith !== undefined ? { startWithValue } : {}),
  }) as any;
  let callableSource = typeof source === 'function' ? source : () => source;
  const subscribeSource = () =>
    callableSource().subscribe({
      next: (x) => (rx.value = x),
      complete: () => (rx.closed = true),
    });
  let sub = subscribeSource();
  if (options?.resubscribeOn) {
    options.resubscribeOn.asObservable().subscribe(() => {
      sub?.unsubscribe?.();
      rx.closed = false;
      subscribeSource();
    });
  }
  return rx;
}

interface RxSourceConstructor<Error = ErrorConstructor> {
  setLoading(value: boolean): void;
  get loading(): boolean;
  setError(value: Error): void;
  get error(): Error;
}
export function fromRxValueWithState<T, Error = ErrorConstructor>(
  source: (state: RxSourceConstructor<Error>) => Observable<T>,
  defaultValue?: T,
  startWithValue?: T,
  options?: { resubscribeOn?: Subject<void> },
) {
  const rx: RXValue<T> & { closed: boolean } = createRxValue<T>(defaultValue, {
    ...(startWith !== undefined ? { startWithValue } : {}),
  }) as any;
  const log = new Logger('loading test');
  const loading = createRxValue(false);
  const error = createRxValue<Error>();
  const setLoading = (value: boolean) => loading.next(value);
  const setError = (value: Error) => error.next(value);
  const subscribeSource = () => {
    return defer(() => {
      return source({ loading: loading.value, setLoading, error: error.value, setError });
    }).subscribe({
      next: (x) => {
        rx.value = x;
        loading.next(false);
      },
      error(err) {
        error.next(err);
      },
      complete: () => {
        rx.closed = true;
        loading.next(false);
      },
    });
  };
  let sub = subscribeSource();
  if (options?.resubscribeOn) {
    options.resubscribeOn.asObservable().subscribe(() => {
      sub?.unsubscribe?.();
      rx.closed = false;
      subscribeSource();
    });
  }
  loading.asObservable().subscribe((x) => log.debug(x));
  return {
    state: rx,
    loading,
    setLoading,
    error,
    setError,
  };
}

export function fromRxValueWithRefresh<T>(source: () => Observable<T>, defaultValue?: T, startClosed?: boolean) {
  const rx: RXValue<T> & { closed: boolean } = createRxValue<T>(defaultValue) as any;
  let callableSource = typeof source === 'function' ? source : () => source;
  const subscribeSource = () =>
    callableSource().subscribe({
      next: (x) => (rx.value = x),
      complete: () => (rx.closed = true),
    });

  let sub = startClosed === true ? null : subscribeSource();
  if (startClosed) rx.closed = true;
  const refreshRx = () => {
    if (sub && !sub.closed) sub.unsubscribe();
    rx.closed = false;
    sub = subscribeSource();
  };

  return { $: rx, refreshRx };
}
export function createRange(from: Date, to: Date): TRange {
  const days = ((x) => (x < 0 ? x * -1 : x))(differenceInDays(to, from));
  const type = days < 27 ? (days < 7 ? (days < 1 ? 'day' : 'week') : 'month') : 'month';
  return {
    name: 'time-range.preset.custom',
    from,
    to,
    rangeType: type,
  };
}
export function DistinctEqual(original: any, ...args: any[]) {
  const _original = JSON.stringify(original);
  return args.every((x) => JSON.stringify(x) === _original);
}
function DistinctBy<T>(mapper?: (a: T) => any) {
  return (source: Observable<T>) =>
    source.pipe(
      distinctUntilChanged((a, b) => {
        return mapper(a) === mapper(b);
      }),
    );
}
export function DistinctJSON<T>(mapper?: (a: T) => any) {
  return (source: Observable<T>) =>
    source.pipe(
      distinctUntilChanged((a, b) => {
        return !hasChange(a, b, mapper);
      }),
    );
}
export function hasChange<T>(a: T, b: T, mapper?: (a: T) => any) {
  if (mapper) return JSON.stringify(mapper(a)) !== JSON.stringify(mapper(b));
  return JSON.stringify(a) !== JSON.stringify(b);
}
export function DistinctComsmos<T>() {
  return (source: Observable<T>) => source.pipe(distinctUntilChanged<T>(DistinctUntilCosmosChange));
}
export function waitFor<T>(signal: Observable<any>) {
  return (source: Observable<T>) =>
    signal.pipe(
      first(),
      switchMap((_) => source),
    );
}
export function waitAsync(ms: number) {
  return new Promise<void>((resolve) => setTimeout(() => resolve(), ms));
}
export const DistinctUntilCompare = {
  ChangedBy: DistinctUntilChangedBy,
  Diff: DistinctUntilDiff,
  ChangedCosmos: DistinctUntilCosmosChange,
  ChangedJson: DistinctEqual,
};
export const distinctUntilChangedJson = DistinctJSON;
export const distinctUntilBy = DistinctBy;
export const distinctUntilChangedCosmos = DistinctComsmos;
export function withPreviousItem<T>(): OperatorFunction<
  T,
  {
    previous?: T;
    current: T;
  }
> {
  return pipe(
    startWith(undefined),
    pairwise(),
    map(([previous, current]) => ({
      previous,
      current: current!,
    })),
  );
}

export function factory<T>(type: { new (): T }): T {
  return new type();
}
export type WithOptional<T = any> = T & { [key: string]: any };
export const NaNZeroify = (val: any) => (Number.isNaN(val) ? 0 : val);
export const roundUpMinute = (date: Date, coff: number = 60000): Date => {
  return new Date(Math.ceil(date.getTime() / coff) * coff);
};
export const flattenProjectUsers =
  <T extends Project>(user: UserSettings) =>
  (p: T): T & { views: { users: Project['users'] } } => {
    if (!p) return p as any;
    const views = {
      users: [
        p.users
          .map((u) => (!u.removed && { ...(user.workspace.users.find((wu) => wu.id === u.id) || {}), ...u }) || null)
          .filter(Boolean),
        p.groups?.reduce((acc, r) => {
          if (r.removed) return acc;
          const gusers = user.workspace.groups.find((x) => x.id === r.id)?.users;
          if (gusers?.length > 0) acc.push(...gusers.map((u) => ({ ...u, role: r.role })));
          return acc;
        }, [] as any[]),
      ]
        .filter(Boolean)
        .reduce(
          (acc, r, i) => {
            if (i > 0) acc.push(...r.filter((u) => !acc.find((fu) => fu.id === u.id)));
            else acc.push(...r);
            return acc;
          },
          [] as Project['users'],
        ),
      ...(p['views'] || {}),
    };
    return {
      ...p,
      views,
    };
  };

export const flattenProjectPinned =
  <T extends Project>(user: UserSettings) =>
  (p: T): T & { views: { users: Project['users'] } } => {
    if (!p) return p as any;
    const views = {
      pinned: user.pinnedProjects.findIndex((x) => x === p.id) !== -1,
      ...(p['views'] || {}),
    };
    return {
      ...p,
      views,
    };
  };

interface SearchItemObject {
  value: string;
  type?: 'partial' | 'required';
}
export function searchItemsByObject(q: string, data: SearchItemObject[]) {
  const fields = data.reduce(
    (acc, r) => {
      if (r.type === 'required') acc.required.push(r.value);
      else if (r.type === 'partial') acc.partial.push(r.value);
      else acc.optional.push(r.value);

      return acc;
    },
    { required: [], optional: [], partial: [] } as {
      required: string[];
      optional: string[];
      partial: string[];
    },
  );
  const matcher = (item: string) => {
    if (!item) return false;
    return item.toLowerCase().indexOf(q?.toLowerCase()) !== -1;
  };
  return (
    (fields.required.length > 0 ? fields.required.every(matcher) : true) &&
    (fields.partial.length > 0 ? fields.partial.some(matcher) : true)
  );
}
export function withSearchForArray<T extends object>(q: string, data: any, searchOptions?: { minLength: number }) {
  const options = searchOptions || { minLength: 1 };
  if (!q || q.length < options.minLength) return () => true;
  return (item: T) =>
    searchItemsByObject(
      q,
      Object.entries(data).map(([key, sq]: [string, any]) => ({ ...sq, value: get(item, key) }) as SearchItemObject),
    );
}

export function mapObjectKeys<T extends Object = any>(obj: T, value: any) {
  if (!obj) return null;
  return Object.keys(obj).reduce((acc, r) => {
    acc[r] = value[r];
    return acc;
  }, obj as T);
}

export const GROUP_ALIAS_MAP = {
  [DEFAULT_PERMISSION_GROUPS.Admin]: 'utils.admins',
  [DEFAULT_PERMISSION_GROUPS.Everyone]: 'utils.anyone',
};

export function parseQP(params: URLSearchParams) {
  const values: Record<string, any> = {};
  for (const [key, value] of params.entries()) {
    values[key] = value;
  }
  return values;
}

export function queryParams(): Record<string, any> {
  let queryString = location.search.split('?', 2);
  let query =
    queryString && queryString.length > 0 && queryString[1]
      ? queryString[1].split('&').reduce(function (l, r) {
          const entry = r ? r.split('=', 2) : null;
          if (entry == null) return l;
          return Object.assign(l, { [entry[0]]: entry[1] });
        }, {})
      : {};
  return query;
}
export function stringify(obj: any) {
  return JSON.stringify(obj);
}
export function parseJson<T = any>(value: string): T {
  if (!value) return null;
  return JSON.parse(value);
}
export function createTranslateArgs(base: TranslateService) {
  return translateArgs.bind(base) as typeof translateArgs;
}
function translateArgs<T extends Object>(value: T): T {
  const translate = this as TranslateService;
  return (
    (value &&
      Object.entries(value)
        .filter(([key, value]) => key && value)
        .reduce((acc, [key, value]) => {
          if (value) acc[key] = translate.instant(value);
          return acc;
        }, {} as T)) ||
    ({} as T)
  );
}
export function parseTimeFormat(date: string, refDate?: Date) {
  if (!refDate || !isValid(refDate)) refDate = startOfToday();
  const value = parseFromString(date, 'HH:mm', refDate);
  if (!value || !isValid(value)) return null;
  return value;
}
/**
 * coerces already serialized date back to {Date} and parses HH:mm format
 * @param date
 * @param refDate
 * @returns
 */
export function coerceTimeFormat(date: string | number | Date, refDate?: Date) {
  if (typeof date === 'object' && isDate(date)) return date;
  if (typeof date === 'number') return new Date(date);
  return parseTimeFormat(date, refDate);
}
export function timeFormatToDuration(value: string) {
  if (!value) return null;
  const [hours, minutes, seconds] = value.split(':').map((x) => (typeof x === 'string' ? Number(x) : null));
  return (hours * 60 + (minutes ?? 0)) * 60 + (seconds ?? 0);
}
export function coerceTimeDuration(date: string, refDate?: Date) {
  return addSeconds(refDate ?? startOfToday(), timeFormatToDuration(date));
}
export function hoursToFormat(hours: any) {
  if (typeof hours !== 'number') return hours;
  return UtilService.parseMS(hours * 3600 * 1000, { showNegative: false, showSeconds: false });
}

export type ScheduleUsage = { used: number; max: number; disabled?: boolean };
type NumberMap = { [key: number]: number };
export function getActiveSchedule(user: UserSettings, date?: Date, allowDisabled?: boolean) {
  const now = new Date();
  if (!date) date = startOfDay(now.getTime());
  const schedule =
    [...(user.workspace.schedules?.users[user.id] || [])]?.sort(firstBy((x) => x.from, 'desc'))?.find((x) => {
      const scheduleStart = !x.from ? null : new Date(x.from).getTime();
      return (
        (allowDisabled || x.enabled) &&
        (!scheduleStart || (date.getTime() >= scheduleStart && scheduleStart <= now.getTime()))
      );
    }) ?? user.workspace.schedules?.workspace;
  if (!schedule?.enabled) return null;
  return schedule;
}
export function parseScheduleStats(
  schedules: WorkspaceUserSchedule,
  times: Time[],
  options?: Partial<{ calculateEveryday: boolean; date: Date; allowDisabled: boolean; includeFuture: boolean }>,
) {
  if (!schedules) return null;
  if (!options) options = { calculateEveryday: false, allowDisabled: false, includeFuture: true };
  const msPerDayMap: { enabled: NumberMap; disabled: NumberMap } = schedules.items.reduce(
    (acc, r) => {
      const accRef = acc[r.enabled ? 'enabled' : 'disabled'];
      if (!(accRef[r.day] >= 0)) accRef[r.day] = 0;
      const dayMS =
        r?.items?.reduce((ms, item) => {
          const diff = (parseTimeFormat(item.to)?.getTime() || 0) - (parseTimeFormat(item.from)?.getTime() || 0);
          return (ms += clamp(diff, 0, diff) / 1000);
        }, 0) || 0; // time in ms
      accRef[r.day] += dayMS;
      return acc;
    },
    { enabled: {}, disabled: {} } as { enabled: NumberMap; disabled: NumberMap },
  ) || { enabled: {}, disabled: {} };
  if (!options.calculateEveryday) {
    const cDay = startOfDay(new Date(options.date || times[0]?.start || Date.now()));
    if (msPerDayMap[cDay.getUTCDay()] === undefined) return null;
  }
  function getDayUsage(day: number, allowDisabled: boolean = false) {
    return msPerDayMap.enabled?.[day] ?? (allowDisabled ? msPerDayMap.disabled?.[day] : null);
  }
  function allDayUsage(allowDisabled: boolean) {
    return { ...msPerDayMap.enabled, ...(allowDisabled ? msPerDayMap.disabled : {}) };
  }
  const now = new Date();
  const daysOfUsage = (times || [])
    .filter((x) => !!x?.end)
    .reduce(
      (acc, r) => {
        const day = startOfDay(new Date(r.start));
        const currentDay = day.getUTCDay();
        const sched = getDayUsage(currentDay, options.allowDisabled);
        if (!options.includeFuture && isFuture(day)) {
          if (!acc.times[currentDay]) acc.times[currentDay] = { max: sched, used: 0, disabled: true };
        }
        if (sched >= 0) {
          const currentMs = Math.abs(r.timeDiff);
          if (!acc.times[currentDay]) acc.times[currentDay] = { max: sched, used: currentMs };
          else acc.times[currentDay].used += currentMs;

          acc.total.used += currentMs;
        }
        return acc;
      },
      {
        times: {},
        total: {
          max: Object.values(allDayUsage(options.allowDisabled)).reduce((macc, mr) => (macc += mr), 0),
          used: 0,
        },
      } as {
        times: { [key: number]: ScheduleUsage };
        total: ScheduleUsage;
      },
    );
  const currentWeek = startOfWeek(options.date || now, { weekStartsOn: 1 });
  return {
    usage: daysOfUsage.times,
    total: {
      ...daysOfUsage.total,
      percent: daysOfUsage.total.used / daysOfUsage.total.max,
    },
    msPerDayMap: allDayUsage(options.allowDisabled),
    isDayEnabled: (day: number) => msPerDayMap.enabled[day] !== undefined,
    isDayFuture: (day: number) => isFuture(addDays(currentWeek, day)),
    enabledDays: Object.entries(msPerDayMap.enabled).map(([key, v]) => {
      const day = Number(key);
      return { day, currentWeek, cap: v };
    }),
    disabledDays: Object.entries(msPerDayMap.disabled).map(([key, v]) => {
      const day = Number(key);
      return { day, currentWeek, cap: v };
    }),
    date: options.date || currentWeek,
    config: schedules,
  };
}
type DayParserOptions = { round: (ms: number | Date) => Date };
export function createScheduleDayParserFromUser(user: UserSettings, date?: Date, options?: DayParserOptions) {
  const sched = getActiveSchedule(user, date ?? new Date());
  if (!sched) return null;
  return createScheduleDayParser(sched as any, options);
}
export function createScheduleDayParser(schedule: WorkspaceUserSchedule, options?: DayParserOptions) {
  if (!options) options = {} as any;
  return function (data: Time[], date: Date) {
    const sitem = schedule.items[(date = startOfDay(date)).getUTCDay()];
    if (!sitem) return null;
    const [cap, capRaw] = sitem?.items?.reduce(
      ([ms, msraw], item) => {
        const diff =
          (!options?.round
            ? parseTimeFormat(item.to)?.getTime() || 0
            : options.round(parseTimeFormat(item.to))?.getTime() || 0) -
          (!options?.round
            ? parseTimeFormat(item.from)?.getTime() || 0
            : options.round(parseTimeFormat(item.from))?.getTime() || 0);
        const diffms = clamp(diff, 0, diff) / 1000;
        if (sitem.enabled) ms += diffms;
        msraw += diffms;

        return [ms, msraw];
      },
      [0, 0],
    ) || [0, 0];
    const usage = data.filter((x) => isSameDay(new Date(x.start), date)).reduce((acc, r) => (acc += r.timeDiff), 0);
    return {
      enabled: schedule.enabled,
      itemEnabled: sitem?.enabled,
      usage,
      cap,
      capRaw,
      schedule: sitem,
    };
  };
}
export type CreateScheduleDayParserValue = ReturnType<ReturnType<typeof createScheduleDayParser>>;
export interface TGMessageBase {
  _source: 'tg';
}
let brdChannel: BroadcastChannel;
export function fetchMessageChannel() {
  const channelName = 'tg_' + environment.serverUrl;
  return brdChannel || (brdChannel = new BroadcastChannel(channelName));
}
export const nextTick = <T extends Function>(fn: T) => {
  return setTimeout(() => {
    fn?.();
  });
};
export const nextTickAsync = () => new Promise<void>((resolve) => nextTick(() => resolve()));

export const sumObjectValues = <T = { [key: string]: number }>(original: T, obj: T) =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    if (typeof acc[key] === 'number') acc[key] += value;
    return acc;
  }, original);
export function sumSchedTimes(sched: WorkspaceUserSchedule) {}
export function resolveRawArgs<T extends Record<string, any>>(args: T) {
  const resolveMap = [
    { keys: ['dailyMaxHours', 'breakBetweenWorkingDays', 'workingHours', 'minutesDue'], resolve: hoursToFormat },
    {
      keys: ['exceededTime'],
      resolve: (value: any) => UtilService.parseMS(value, { showSeconds: false, showNegative: false }),
    },
  ];
  return Object.entries(args)
    .map(([key, value]) => {
      const resolvedValue = resolveMap.find((x) => x.keys.includes(key))?.resolve(value) ?? value;
      return [key, resolvedValue];
    })
    .reduce((acc, [key, value]) => {
      if (!acc[key]) acc[key] = value;
      return acc;
    }, {});
}
export async function asyncWrapTimeout<T = any>(fn: () => Promise<T>, timeout: number) {
  let tn: NodeJS.Timeout;
  let log = new Logger('async w/ timeout');
  return await new Promise(async (resolve, reject) => {
    let prom = fn();
    prom
      .then((data) => {
        log.debug(data);
        resolve(data);
      })
      .catch(reject)
      .finally(() => tn && clearTimeout(tn));
    tn = setTimeout(() => {
      prom = null;
      const err = new Error('Timed out');
      err.name = 'timeoutErr';
      reject(err);
    }, timeout);
  });
}
export function getVarName(obj: any) {
  return Object.keys(obj)[0];
}
export function isMobile() {
  return document.body.clientWidth < 768;
}
export function fromSignal<T>(sig: Signal<T>) {
  return toObservable<T>(sig).pipe(takeUntilDestroyed());
}
export function fromModel<T>(sig: ModelSignal<T>): Observable<T> {
  const obs = new Subject();
  sig.subscribe((x) => obs.next(x));
  return obs.asObservable().pipe(takeUntilDestroyed()) as Observable<T>;
}
export function coerce2DArray<T>(data: T): T[] {
  return Array.isArray(data) ? data : [data];
}
export function userIsAwaitingActivation(id: string) {
  return id && /^custom-/.test(id);
}
export { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
export function flattenEntityResponse<T, R = T>(data: T): R {
  return ((data && ([data].flat()?.filter(Boolean) ?? [])) || []) as R;
}
